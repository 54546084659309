.ant-slider-rail {
  height: 6px !important;
  background: #d9d9d9 !important;
  border-radius: 5px !important;
}
.ant-tooltip-inner {
  background: #626262 !important;
  border-radius: 5px !important;
  padding: 6px 15px !important;
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: #626262 !important;
}
