@font-face {
  font-family: 'MarkOT';
  src: url('../fonts/Mark\ OT.ttf');
  font-weight: 100 400;
}

@font-face {
  font-family: 'MarkOT';
  src: url('../fonts/MarkOT-Medium.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'MarkOT';
  src: url('../fonts/MarkOT-Bold.otf');
  font-weight: 600 700;
}

* {
  font-family: 'MarkOT', sans-serif;
  margin: 0;
  padding: 0;
  font-weight: lighter;
}

h1 {
  font-weight: 700;
}

h2 {
  font-weight: 500;
}

h3 {
  font-weight: 500;
}

body {
  width: 100%;
  height: 100%;
  counter-reset: page;
}

a {
  text-decoration: none;
  color: black;
}
li {
  list-style-type: none;
}

.plotly-container-div {
  width: 100%;
  display: flex;
}

.ant-upload.ant-upload-drag {
  height: auto !important;
}

.ant-table-wrapper .ant-table {
  scrollbar-color: unset !important;
}
div::-webkit-scrollbar {
  width: 8px !important;
  margin: auto !important;
}
/* Ascenseur */
.ant-table-wrapper .ant-table div::-webkit-scrollbar-thumb {
  background: #ccd0d4 !important;
  border-radius: 5px !important;
}
.ant-table-wrapper .ant-table div::-webkit-scrollbar-track {
  background: #f8f8f8 !important;
  width: 10px !important;
}

.ant-table-wrapper .ant-table div::-webkit-scrollbar:horizontal {
  height: 8px !important;
}

.ant-table-wrapper .ant-table div::-webkit-scrollbar-track:horizontal {
  height: 10px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 5px !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border: 1px solid #d9d9d9 !important;
  box-shadow: none !important;
}

/* ant modal */
.ant-modal .ant-modal-content {
  border-radius: 10px;
}

.ant-modal .ant-modal-content .ant-modal-header {
  background: transparent;
  border-bottom: none;
  border-radius: 10px;
  text-align: center;
}
.experiment-plan-modal .ant-modal-content .ant-modal-header {
  text-align: left;
}

.experiment-plan-modal .ant-modal-content .ant-modal-body {
  padding-top: 0px;
}
.experiment-plan-modal .ant-modal-content {
  height: 80vh;
}

@media screen and (max-width: 992px) {
  .ant-alert-with-description {
    flex-direction: column;
  }

  #dashbord-select-expended {
    position: initial !important;
    width: 100% !important;
  }
  .ant-card-bordered {
    height: auto !important;
  }

  #sectionHome {
    height: 70vh !important;
  }

  #dashbord-main {
    margin-left: 0 !important;
    width: 100% !important;
  }

  #dashbord {
    flex-direction: column !important;
  }

  #dashboard-select-collapsed {
    flex-direction: column !important;
    display: flex !important;
    width: 100% !important;
    height: 50px !important;
    position: relative !important;
  }
  #card-dashbord-select .ant-card-body {
    display: flex !important;
    flex-flow: row-reverse !important;
    flex-wrap: nowrap !important;
    align-items: center;
  }

  #card-dashbord-select .ant-card-body #icon {
    flex-direction: row !important;
    width: 80% !important;
    justify-content: space-around !important;
  }
  #card-dashbord-select .ant-card-body #icon svg {
    margin-bottom: 0 !important;
  }
}

@media screen and (max-width: 768px) {
}

.clickable-icon {
  cursor: pointer;
  font-size: 13pt;
}

/* Default font-weight for <b> is "bolder" but there is no "bolder" font in the project (MarkOT), only bold */
b {
  font-weight: bold !important;
}

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: inherit !important;
}

.pdf-box {
  background-color: white;
  width: 70%;
  padding: 50px;
  margin-top: 50px;
  margin-bottom: 100px;
}

footer {
  font-size: 20px;
  color: #f00;
  text-align: center;
}
.pdf-visible {
  display: none !important;
  height: 0;
}

/* Printable rules */
@media print {
  .no-scroll {
    overflow: visible !important;
    max-height: none !important;
    max-width: 300px;
    min-width: 150px;
  }

  .tag-multilign {
    word-break: break-all !important;
    white-space: inherit !important;
  }

  .pagebreak {
    page-break-after: always;
  }

  .pdf-visible {
    display: block !important;
    height: '50vh' !important;
    overflow: 'visible' !important;
  }

  .ant-layout {
    background-color: white !important;
    margin-left: 0 !important;
  }

  .ant-layout-header {
    display: none;
  }
  .ant-layout-sider {
    display: none;
    width: 0;
  }

  .pdf-box {
    display: table;
    width: 100% !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    margin-left: 30px !important;
    margin-right: 30px !important;
  }

  .pdf-invisible {
    display: none !important;
    height: 0 !important;
  }

  ::placeholder {
    color: transparent;
  }

  ::-webkit-input-placeholder {
    /* WebKit browsers */
    color: transparent !important;
  }
  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: transparent !important;
  }
  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: transparent !important;
  }
  :-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: transparent !important;
  }

  footer {
    position: fixed;
    bottom: 0;
  }
  /* footer:after {
    content: counter(page);
  } */
}

/* @page {
  margin: 2cm 2cm 2cm 2cm;
} */

.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: -4px 0 8px rgb(7 6 35 / 6%) !important;
}

.ant-drawer-header-title {
  flex-flow: row-reverse;
  margin-top: 8px;
  text-align: left;
}

.ant-modal-wrap {
  z-index: 1001 !important;
}

/* .ant-checkbox-checked .ant-checkbox-inner {
  background: #1890ff !important;
  border-color: #1890ff !important;
} */

.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  border-radius: 0 5px 5px 0 !important;
}

.ant-input-affix-wrapper {
  border-radius: 5px 0 0 5px !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: white !important;
}

.ant-card-hoverable:hover {
  background-color: #1890ff30 !important;
  box-shadow: 16px 16px 16px 0 #03030f10 !important;
}

.helpItem:hover {
  color: #1890ff !important;
}
.ant-dropdown-menu {
  padding: 0 !important;
  border-radius: 5px !important;
}

.ant-dropdown-menu-item:hover {
  border-radius: 5px !important;
}
