$colorBatches: ("blue": #1890FF, "blueDark": #0069ca, "blueLight": #7ec1ff, "brown": #9A6324, "brownDark": #5c3b16, "brownLight": #d6974e,"cyan": #13C2C2,"cyanDark": #0c7c7c,"cyanLight": #4deeee,"geekblue": #2F54EB,"geekblueDark": #1233bb,"geekblueLight": #8ca0f4,"gold": #FAAD14,"goldDark": #bd7f04,"goldLight": #fcd078,"green": #52C41A,"greenDark": #368011,"greenLight": #8ae95b,"lime": #A0D911,"limeDark": #6c920b,"limeLight": #c8f25e,"magenta": #EB2F96,"magentaDark": #bb126f,"magentaLight": #f48cc5,"maroon": #800000,"maroonDark": #330000,"maroonLight": #e60000,"navy": #000075,"navyDark": #000028,"navyLight": #0000db,"orange": #FA8C16,"orangeDark": #bf6504,"orangeLight": #fcbd7a,"purple": #722ED1,"purpleDark": #502092,"purpleLight": #aa82e3,"red": #F5222D,"redDark": #c20912,"redLight": #f9848a,"teal": #469990,"tealDark": #2e645f,"tealLight": #80c5bd,"volcano": #FA541C,"volcanoDark": #c53504,"volcanoLight": #fc9f80)

@each $name, $color in $colorBatches
  .checkbox-#{$name} > .ant-tree-checkbox-checked .ant-tree-checkbox-inner,
  .checkbox-#{$name} > .ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after,
  .checkbox-#{$name} > .ant-tree-checkbox-checked:not(.ant-tree-checkbox-disabled):hover .ant-tree-checkbox-inner
    background-color: $color
    border-color: $color 

@each $name, $color in $colorBatches
  .checkbox-#{$name} > .ant-checkbox-checked .ant-checkbox-inner,
  .checkbox-#{$name} > .ant-checkbox-indeterminate .ant-checkbox-inner::after
    background-color: $color
    border-color: $color

@each $name, $color in $colorBatches
  .checkbox-#{$name} > .ant-tree-checkbox-indeterminate:hover .ant-tree-checkbox-inner
    border-color: #d9d9d9 !important

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after
  background-color: rgba(0, 0, 0, 0.25) !important
  border-color: #rgba(0, 0, 0, 0.25) !important

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-disabled .ant-tree-checkbox-inner
  background-color: rgba(0, 0, 0, 0.04) !important
  border-color: #d9d9d9 !important

.ant-tree-checkbox-checked .ant-tree-checkbox-disabled .ant-tree-checkbox-inner::after
  opacity: 1
  transform: rotate(45deg) scale(1) translate(-50%, -50%)
  transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s

.ant-tree-checkbox-checked:hover .ant-tree-checkbox-disabled .ant-tree-checkbox-inner
  background-color: rgba(0, 0, 0, 0.04) !important
  border-color: #d9d9d9 !important